import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import { Pagination } from '../../src';
import { Row } from './helpers';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "pagination"
    }}>{`Pagination`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Pagination from '@mfrm/mfcl/Pagination'
`}</code></pre>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`Pagination allows you to paginate through a list of items.`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Pagination} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <Playground __position={1} __code={'<Row>\n  <Pagination totalItems={10} itemsPerPage={5} />\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Pagination,
      Row,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <Pagination totalItems={10} itemsPerPage={5} mdxType="Pagination" />
  </Row>
    </Playground>
    <h2 {...{
      "id": "sibling-pages"
    }}>{`Sibling Pages`}</h2>
    <Playground __position={2} __code={'<Row>\n  <Pagination totalItems={20} itemsPerPage={5} siblingPages={2} />\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Pagination,
      Row,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <Pagination totalItems={20} itemsPerPage={5} siblingPages={2} mdxType="Pagination" />
  </Row>
    </Playground>
    <h2 {...{
      "id": "show-item-count"
    }}>{`Show Item Count`}</h2>
    <Playground __position={3} __code={'<Row>\n  <Pagination\n    totalItems={60}\n    itemsPerPage={5}\n    showItemCount\n    countName=\"Reviews\"\n  />\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Pagination,
      Row,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <Pagination totalItems={60} itemsPerPage={5} showItemCount countName="Reviews" mdxType="Pagination" />
  </Row>
    </Playground>
    <h2 {...{
      "id": "items-per-page"
    }}>{`Items Per Page`}</h2>
    <Playground __position={4} __code={'<Row>\n  <Pagination itemsPerPage={10} totalItems={100} showItemCount />\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Pagination,
      Row,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <Pagination itemsPerPage={10} totalItems={100} showItemCount mdxType="Pagination" />
  </Row>
    </Playground>
    <h2 {...{
      "id": "ellipses"
    }}>{`Ellipses`}</h2>
    <Playground __position={5} __code={'<Row>\n  <Pagination\n    totalItems={76}\n    itemsPerPage={10}\n    showItemCount\n    ellipses\n    siblingPages={0}\n    boundaryCount={{ start: 3, end: 2 }}\n  />\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Pagination,
      Row,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
   <Pagination totalItems={76} itemsPerPage={10} showItemCount ellipses siblingPages={0} boundaryCount={{
          start: 3,
          end: 2
        }} mdxType="Pagination" />
  </Row>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      